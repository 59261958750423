import React from 'react';
import { KesproHelmet } from '@kesko/kespro-ui-common';
import { baseUrl } from '../config.js';
import { defaultDescriptions, logoUrl, } from '../meta.js';
import { translations } from '../staticData.js';
import { useAppSelector } from '../hooks.js';
export const KesproComHelmet = (props) => {
    const language = useAppSelector((state) => state.i18n.language);
    const defaultedProps = Object.assign(Object.assign({}, props), { description: props.description || defaultDescriptions.main, titleTemplate: translations[language].common.headerTitleTemplate, defaultTitle: translations[language].common.headerDefaultTitle, defaultImage: logoUrl, baseUrl });
    return React.createElement(KesproHelmet, Object.assign({}, defaultedProps));
};
