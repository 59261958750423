import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isLocation, isSalesPerson, isWholesale, } from '../../../common/apiTypes.js';
import { navigationItems } from '../../staticData.js';
import { contactListPageUrls, customerServiceListPageUrls, salesContactListPageUrls, } from '../../../common/urlPaths.js';
import { useGetContactPageDescriptionsQuery, useGetContactsQuery } from '../../services/api.js';
import { useAppDispatch, useAppSelector } from '../../hooks.js';
import { setDebouncedSearchPhraseWithUpdates, setSearchPhrase, toggleRegionFilterWithUpdates, toggleTypeFilterWithUpdates, } from '../../reducers/contactsSlice.js';
import { debounce } from 'lodash-es';
import { AUTO_SEARCH_DEBOUNCE_MS } from '../../../common/constants.js';
import { ChipsFilter } from '../../components/ChipsFilter.js';
import { filterBySalesAreaLocation, filterBySalesType, searchContacts, } from '../../util/contactsFilter.js';
import { renderAlternateLinks } from '../../components/renderAlternateLinks.js';
import { ContactType, getLocalizedRegionFilterOptions, getLocalizedTypeFilterOptions, i18nTexts as i18nTextsRoot, } from './i18n.js';
import { parseCacheQueryParams } from '../../../common/queryStrings.js';
import { Icon } from '../../Icon.js';
import { ContactList } from '../../components/ContactList.js';
import { ContactReferrer } from '../../components/ContactReferrer.js';
import { FilterBar } from '../../components/FilterBar.js';
import { VerticalContainer } from '../../components/FlexboxContainer.js';
import { KesproComHelmet } from '../../components/KesproComHelmet.js';
import { LoadingIndicator } from '../../components/LoadingIndicator.js';
import { NotFoundComponent } from '../../components/NotFoundComponent.js';
import { SearchBox } from '../../components/SearchBox.js';
import { shallowEqual } from 'react-redux';
import style from './style.scss';
export const mapContactType = (contactType) => {
    switch (contactType) {
        case ContactType.Noutotukut:
            return 'wholesale';
        case ContactType.Asiakaspalvelu:
            return 'customerService';
        case ContactType.Sales:
            return 'salesPerson';
        default:
            return '';
    }
};
const applyContactFiltersAndSort = (contacts, searchPhrase, options) => {
    const { salesTypeFilters = {}, regionFilters = {}, contactType } = options;
    const filters = [
        (contact) => {
            if (contactType !== ContactType.All) {
                return contact.contentType === mapContactType(contactType);
            }
            return isWholesale(contact) || isLocation(contact) || isSalesPerson(contact);
        },
        (contact) => filterBySalesType(contact, salesTypeFilters),
        (contact) => filterBySalesAreaLocation(contact, regionFilters),
    ];
    return searchContacts(contacts, searchPhrase, filters, contactType);
};
const applyDescriptionFilter = (descriptions, contactType) => {
    const contentFilter = (ct) => R.propEq('category', ct);
    const descriptionFilter = (ds) => ds.length
        ? R.filter(contactType ? contentFilter(mapContactType(contactType)) : contentFilter('all'), ds)
        : [];
    return R.head(descriptionFilter(descriptions));
};
const CustomerServiceBoxes = ({ i18nTexts }) => {
    const language = useAppSelector((state) => state.i18n.language);
    const texts = i18nTexts.customerServiceBox;
    const customerServiceBoxTexts = [
        { title: texts.frequentlyAskedQuestionsLabel, info: texts.frequentlyAskedQuestionsValue, icon: 'FAQ' },
        { title: texts.emailLabel, info: 'kespro.asiakas<wbr>palvelu<wbr>@kesko.fi', icon: 'mail-white', href: 'mailto:kespro.asiakaspalvelu@kesko.fi' },
        { title: texts.phoneLabel, info: '010 532 4300', icon: 'phone-white', href: 'tel:+358105324300' },
    ];
    if (language !== 'fi') {
        customerServiceBoxTexts.shift();
    }
    const openGiosg = React.useCallback((e) => {
        if (e.currentTarget.id === 'usein kysyttyä') {
            // If Giosg is found click it, otherwise open "Frequently asked" page
            const giosgButton = document.querySelector('[aria-label="Palvelupolut interaction"] button');
            if (giosgButton) {
                giosgButton.click();
            }
            else {
                window.location.assign('/usein-kysyttya');
            }
        }
    }, []);
    return (React.createElement("div", { className: style.customerServiceBoxes }, customerServiceBoxTexts.map((boxInformation) => React.createElement("a", { href: boxInformation.href, id: boxInformation.title, className: style.boxContainer, key: boxInformation.title, onClick: openGiosg },
        React.createElement("div", { className: style.circleSpace },
            React.createElement("div", { className: style.iconCircle },
                React.createElement(Icon, { key: boxInformation.icon, type: boxInformation.icon, size: 28 }))),
        React.createElement("div", { className: style.boxContent },
            React.createElement("h3", { className: style.boxTitle },
                " ",
                boxInformation.title.toUpperCase(),
                " "),
            React.createElement("span", { className: style.boxInfo, dangerouslySetInnerHTML: { __html: boxInformation.info } }))))));
};
const ContactListWithDescription = ({ description, contacts, contactType, i18nTexts, searchPhrase, chipFilters, }) => (React.createElement(React.Fragment, null,
    description && React.createElement("p", { className: style.description }, description.description),
    React.createElement("div", { className: style.contactListContainer },
        chipFilters,
        contacts.length
            ? React.createElement(ContactList, { contacts: applyContactFiltersAndSort(contacts, searchPhrase, { contactType }) })
            : React.createElement("p", { className: style.notFoundText }, i18nTexts.contactsNotFound))));
const ContactContentRenderer = ({ path, description, contacts, contactType, debouncedSearch, handleSearchPhraseChange, i18nTexts, searchPhrase, location, push, replace, }) => {
    const { salesTypeFilters, regionFilters, language } = useAppSelector((state) => ({
        salesTypeFilters: state.contacts.salesTypeFilters,
        regionFilters: state.contacts.regionFilters,
        language: state.i18n.language,
    }), shallowEqual);
    const dispatch = useAppDispatch();
    const typeFilterOptions = getLocalizedTypeFilterOptions(language);
    const regionFilterOptions = getLocalizedRegionFilterOptions(language);
    const handleRegionFilterSelected = (value) => {
        dispatch(toggleRegionFilterWithUpdates(value, location, push, replace));
    };
    const handleTypeFilterSelected = (value) => {
        dispatch(toggleTypeFilterWithUpdates(value, location, push, replace));
    };
    const wholesaleContacts = [];
    const salesPersonContacts = [];
    const locationContacts = [];
    const filteredContacts = applyContactFiltersAndSort(contacts, searchPhrase, { contactType, regionFilters, salesTypeFilters });
    filteredContacts.forEach((contact) => {
        if (isWholesale(contact)) {
            wholesaleContacts.push(contact);
        }
        else if (isSalesPerson(contact)) {
            salesPersonContacts.push(contact);
        }
        else if (isLocation(contact)) {
            locationContacts.push(contact);
        }
    });
    switch (path) {
        case contactListPageUrls.fi:
        case contactListPageUrls.en:
        case contactListPageUrls.sv:
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: style.contactDescription },
                    React.createElement("p", null, i18nTexts.mainContactListPage.description),
                    React.createElement("p", { className: style.customerServiceText }, i18nTexts.mainContactListPage.customerServiceOpeningHours)),
                React.createElement("h2", null, i18nTexts.mainContactListPage.customerServiceHeader),
                React.createElement(CustomerServiceBoxes, { i18nTexts: i18nTexts }),
                React.createElement("h2", null, i18nTexts.mainContactListPage.searchHeader),
                React.createElement("div", { className: style.searchContainer },
                    React.createElement(SearchBox, { onChange: handleSearchPhraseChange, onSearch: (_value) => {
                            debouncedSearch.flush();
                        }, searchPhrase: searchPhrase, placeholder: i18nTexts.searchInputPlaceholder })),
                React.createElement("div", { className: style.chipsFilterContainer },
                    React.createElement(ChipsFilter, { className: style.filterContainer, name: "type", onSelected: handleTypeFilterSelected, selected: salesTypeFilters, options: typeFilterOptions, size: "lg" }),
                    React.createElement(ChipsFilter, { className: style.filterContainer, name: "region", onSelected: handleRegionFilterSelected, selected: regionFilters, options: regionFilterOptions })),
                React.createElement("div", { className: style.contactListContainer, "aria-live": "polite" }, filteredContacts.length
                    ? (React.createElement(React.Fragment, null,
                        React.createElement(ContactList, { contacts: wholesaleContacts, header: i18nTexts.contactList.wholesaleHeader }),
                        React.createElement(ContactList, { contacts: locationContacts, header: i18nTexts.contactList.locationsHeader }),
                        React.createElement(ContactList, { contacts: salesPersonContacts, header: i18nTexts.contactList.salesHeader }))) : (React.createElement("p", { className: style.notFoundText }, i18nTexts.contactsNotFound)))));
        case customerServiceListPageUrls.fi:
        case customerServiceListPageUrls.en:
        case customerServiceListPageUrls.sv:
            return (React.createElement(React.Fragment, null,
                React.createElement(CustomerServiceBoxes, { i18nTexts: i18nTexts }),
                description && React.createElement("p", { className: style.description }, description.description)));
        default: {
            const chipFilters = [
                React.createElement(ChipsFilter, { className: style.filterContainer, name: "region", key: "chip-region", onSelected: handleRegionFilterSelected, selected: regionFilters, options: regionFilterOptions }),
            ];
            if (Object.values(salesContactListPageUrls).includes(path)) {
                chipFilters.unshift(React.createElement(ChipsFilter, { className: style.filterContainer, name: "type", key: "chip-type", onSelected: handleTypeFilterSelected, selected: salesTypeFilters, options: typeFilterOptions, size: "lg" }));
            }
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: style.searchContainer },
                    React.createElement(SearchBox, { onChange: handleSearchPhraseChange, onSearch: (_value) => {
                            debouncedSearch.flush();
                        }, searchPhrase: searchPhrase, placeholder: i18nTexts.searchInputPlaceholder })),
                React.createElement(ContactListWithDescription, { description: description, contacts: [...salesPersonContacts, ...wholesaleContacts], contactType: contactType, i18nTexts: i18nTexts, searchPhrase: searchPhrase, chipFilters: chipFilters })));
        }
    }
};
const ContactListView = ({ contactType, contacts, description, i18nTexts, location }) => {
    const dispatch = useAppDispatch();
    const { language, searchPhrase } = useAppSelector((state) => ({
        language: state.i18n.language,
        searchPhrase: state.contacts.searchPhrase,
    }), shallowEqual);
    const { push, replace } = useHistory();
    const debouncedSearch = useCallback(debounce((val, loc) => {
        dispatch(setDebouncedSearchPhraseWithUpdates(val, loc, push, replace));
    }, AUTO_SEARCH_DEBOUNCE_MS), []);
    const handleSearchPhraseChange = (value) => {
        dispatch(setSearchPhrase(value));
        debouncedSearch(value, location);
    };
    const menuBarItems = navigationItems.secondary[language].find((item) => item.subItems && item.to.startsWith(contactListPageUrls[language]));
    return (React.createElement(VerticalContainer, { horizontalAlign: "center", wrap: false },
        React.createElement("div", { className: style.mobileNavContainer, "data-pw": "headerSubNav" },
            React.createElement(FilterBar, { filters: (menuBarItems === null || menuBarItems === void 0 ? void 0 : menuBarItems.subItems) || [], pathName: location.pathname, className: style.hideOnLarge })),
        React.createElement("header", { className: style.header },
            React.createElement("h1", null, i18nTexts.mainHeaderTexts[contactType])),
        React.createElement(ContactContentRenderer, { path: location.pathname, description: description, contacts: contacts, contactType: contactType, debouncedSearch: debouncedSearch, handleSearchPhraseChange: handleSearchPhraseChange, i18nTexts: i18nTexts, location: location, push: push, replace: replace, searchPhrase: searchPhrase }),
        React.createElement(ContactReferrer, { language: language })));
};
export const ContactListPage = (props) => {
    const { language, locale, localizedSlugs, contactType } = useAppSelector((state) => ({
        language: state.i18n.language,
        locale: state.i18n.locale,
        localizedSlugs: state.i18n.localizedSlugs,
        contactType: state.contacts.contactType,
    }), shallowEqual);
    const cacheQueryParams = parseCacheQueryParams(props.location);
    const contactsQueryResult = useGetContactsQuery(Object.assign({ locale }, cacheQueryParams));
    const descriptionQueryResult = useGetContactPageDescriptionsQuery(Object.assign({ locale }, cacheQueryParams));
    const i18nTexts = i18nTextsRoot[language];
    if (contactsQueryResult.data && descriptionQueryResult.data) {
        const contactPageDescription = applyDescriptionFilter(descriptionQueryResult.data, contactType);
        return (React.createElement("div", null,
            React.createElement(KesproComHelmet, { title: i18nTexts.pageTitles[contactType], canonicalPath: props.location.pathname, description: contactPageDescription === null || contactPageDescription === void 0 ? void 0 : contactPageDescription.description }, renderAlternateLinks(language, localizedSlugs)),
            React.createElement(ContactListView, { contactType: contactType, contacts: contactsQueryResult.data, description: contactPageDescription, i18nTexts: i18nTexts, location: props.location })));
    }
    if (contactsQueryResult.isError || descriptionQueryResult.isError) {
        return React.createElement(NotFoundComponent, null);
    }
    return React.createElement(LoadingIndicator, null);
};
